<script>
const rotation = [
  '"I hate it lmao I want to guess myself"',
  '"Oh man I suck" - Fletcher M',
  '"Looks too much like wordle"',
  '"No make it different"',
  '"I’ll be honest this is a bad idea"',
  '"But that takes away all the fun of figuring it out"',
  '"Did you even try to make this good"',
  '"Hate the concept still"',
  '"I love it" - Nic V',
  '"I liked pressing the button" - Reed C',
  '"My luck is smart" - Fletcher M',
  '"My wife hates wordle but loves randle" - Nate G',
];
export default {
  data() {
    return {
      rotation,
      index: 0,
      fadeOut: false,
    };
  },
  mounted() {
    setInterval(() => {
      this.fadeOut = true;
      setTimeout(() => {
        this.index++;
        this.fadeOut = false;
      }, 500);
    }, 5000);
  },
};
</script>
<template>
  <h2 class="sub-title" :class="{ 'fade-out': fadeOut }">
    {{ this.rotation[this.index % this.rotation.length] }}
  </h2>
</template>
<style lang="scss">
.sub-title {
  opacity: 1;
  transition: opacity 250ms linear;
  &.fade-out {
    opacity: 0;
  }
}
</style>
