<script>
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import {
  wordScore,
  filteredWordList,
  cumulativeInformation,
} from "./algorithm.js";
import { allWords } from "./words.js";
import Square from "./Square.vue";

export default {
  name: "Game",
  components: { Square },
  props: {
    word: {
      type: String,
      required: true,
    },
    guessedWords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gameOverMessage: false,
    };
  },
  watch: {
    canSpin(val) {
      if (val === false) {
        setTimeout(() => {
          this.gameOverMessage = true;
        }, 3200);
      }
      if (val) {
        this.gameOverMessage = false;
      }
    },
  },
  computed: {
    squareChars() {
      const filled = this.guessedWords.reduce(
        (a, v) => [...a, ...v.split("")],
        []
      );
      return Array(30)
        .fill(null)
        .map((v, i) => filled[i] || v);
    },
    squareCharColors() {
      return this.guessedWords.reduce((a, guessedWord) => {
        return [...a, ...wordScore(this.word, guessedWord)];
      }, []);
    },
    squareCharPositions() {
      return this.guessedWords.reduce((a, guessedWord, x) => {
        if (x === 0) {
          return [0, 1, 2, 3, 4];
        }

        const info = cumulativeInformation(
          this.word,
          this.guessedWords.slice(0, x)
        );
        const positionsOfKnownGreens = Object.values(info).reduce((a, v) => {
          //console.log(Object.keys(v.posIncl));
          return [...a, ...Object.keys(v.posIncl).map(Number)];
        }, []);

        const positions = sortBy([0, 1, 2, 3, 4], (i) =>
          positionsOfKnownGreens.includes(i) ? i : 10 + i
        );

        const positions2 = positions.reduce((a, i, j) => {
          a[i] = j;
          return a;
        }, Array(5).fill(null));

        return [...a, ...positions2];
      }, []);
    },
    canSpin() {
      return (
        this.guessedWords.length < 6 && !this.guessedWords.includes(this.word)
      );
    },
  },
  mounted() {
    // console.log(this.canSpin, this.guessedWords, this.word);
    if (!this.canSpin) {
      this.gameOverMessage = true;
    }
  },
  methods: {
    get,
    //todo: pick words with fewest dupes?
    pickNextWord() {
      // console.log("picking next word");
      const remainingWords =
        this.guessedWords.length > 0
          ? filteredWordList(this.word, allWords, this.guessedWords)
          : allWords;
      // console.log(`${remainingWords.length} words to pick from`);
      const pickedWord =
        remainingWords[Math.floor(Math.random() * remainingWords.length)];
      // console.log(pickedWord);
      return pickedWord;
    },
    guessNextWord() {
      if (!this.canSpin) {
        return;
      }
      // console.log("guessing next word");
      const newGuessedWords = [...this.guessedWords, this.pickNextWord()];
      this.$emit("guessed", newGuessedWords);
    },
  },
};
</script>

<template>
  <div class="game">
    <div class="grid-holder">
      <div class="grid">
        <Square
          v-for="(guessedChar, i) in squareChars"
          :key="i"
          :character="guessedChar"
          :color="squareCharColors[i]"
          :position="get(squareCharPositions, [i], i % 5)"
        />
      </div>
    </div>
    <div>
      <button
        @click="guessNextWord"
        class="guess-button"
        :disabled="!canSpin"
        :class="{ disabled: gameOverMessage }"
      >
        <span v-if="!gameOverMessage">SPIN</span>
        <span v-else>Game Over</span>
      </button>
      <p style="text-align: center; margin: 8px 0 0; font-size: 12px">
        &nbsp;<span v-if="gameOverMessage">New word drops at midnight!</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.game {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: calc(100% - 50px);
}
.grid-holder {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
}
.grid {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  max-width: calc(83.333333vh - 125px);
}
.guess-button {
  display: block;
  border: none;
  padding: 0;
  width: 200px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
  text-decoration: none;
  background: linear-gradient(
    150deg,
    rgba(220, 74, 255, 1) 0%,
    rgba(182, 36, 255, 1) 100%
  );
  border-radius: 8px;
  color: #000000;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 20px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  &.disabled {
    background-image: none;
    background-color: #ddd;
  }
}
</style>
