<script>
import {
  wordScore,
  isGameOver,
  isGameWon,
  GREEN,
  YELLOW,
  GREY,
} from "./algorithm.js";
export default {
  name: "Statistics",
  props: {
    stats: {
      type: Object,
      default: () => {},
    },
    results: {
      type: String,
      default: null,
    },
    word: {
      type: String,
      required: true,
    },
    guessedWords: {
      type: Array,
      required: true,
    },
    dayIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fallbackShare: false,
      copied: false,
      resetConfirm: false,
    };
  },
  computed: {
    textToShare() {
      return `Randle ${this.dayIndex} ${
        this.gameWon ? this.guessedWords.length : "X"
      }/6

${this.emojiGrid}

https://randlegame.com`;
    },
    gameOver() {
      return isGameOver(this.word, this.guessedWords);
    },
    gameWon() {
      return isGameWon(this.word, this.guessedWords);
    },
    emojiGrid() {
      const emojiMap = { [GREEN]: "🟪", [YELLOW]: "🟨", [GREY]: "⬜️" };
      return this.guessedWords
        .map((guessedWord) =>
          wordScore(this.word, guessedWord)
            .map((c) => emojiMap[c])
            .join("")
        )
        .join("\n");
    },
    played() {
      return Object.keys(this.stats).length;
    },
    win() {
      if (this.played === 0) {
        return 0;
      }
      return Math.round(
        (100 * Object.values(this.stats).filter((v) => v > 0).length) /
          this.played
      );
    },
    currentPlayStreak() {
      let streak = 0;
      let day = this.dayIndex;
      while (day in this.stats) {
        streak++;
        day--;
      }
      return streak;
    },
    currentWinStreak() {
      let streak = 0;
      let day = this.dayIndex;
      while (day in this.stats && this.stats[day] > 0) {
        streak++;
        day--;
      }
      return streak;
    },
    maxPlayStreak() {
      let streak = 0;
      let streakMax = 0;
      let day = 0;
      while (day <= this.dayIndex) {
        if (day in this.stats) {
          streak++;
          streakMax = Math.max(streak, streakMax);
        } else {
          streak = 0;
        }
        day++;
      }
      return streakMax;
    },
    maxWinStreak() {
      let streak = 0;
      let streakMax = 0;
      let day = 0;
      while (day <= this.dayIndex) {
        if (day in this.stats && this.stats[day] > 0) {
          streak++;
          streakMax = Math.max(streak, streakMax);
        } else {
          streak = 0;
        }
        day++;
      }
      return streakMax;
    },
  },
  methods: {
    shareResults() {
      this.copyTextToClipboard(this.textToShare);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackShare = true;
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copied = true;
        },
        () => {
          this.fallbackShare = true;
        }
      );
    },
    reset() {
      this.$emit("reset");
      this.resetConfirm = false;
    },
  },
};
</script>

<template>
  <div class="statistics">
    <div class="statistics__cover" @click="$emit('close')"></div>
    <div class="statistics__inner">
      <button type="button" class="statistics__close" @click="$emit('close')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path
            fill="currentColor"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </button>
      <h2>Statistics</h2>
      <table>
        <tr class="vals">
          <td>{{ played }}</td>
          <td>{{ win }}</td>
          <td>{{ currentPlayStreak }}</td>
          <td>{{ currentWinStreak }}</td>
          <td>{{ maxPlayStreak }}</td>
          <td>{{ maxWinStreak }}</td>
        </tr>
        <tr class="caps">
          <td>Played</td>
          <td>Win %</td>
          <td>Current Playing Streak</td>
          <td>Current Winning Streak</td>
          <td>Max Playing Streak</td>
          <td>Max Winning Streak</td>
        </tr>
      </table>
      <div v-if="gameOver">
        <!-- <div v-if="gameWon"> -->
        <p v-if="!fallbackShare">
          <button type="button" class="share-button" @click="shareResults">
            SHARE<svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path
                fill="currentColor"
                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
              ></path>
            </svg>
          </button>
        </p>
        <p v-if="copied" class="copied">Copied results to clipboard</p>
        <p v-if="fallbackShare">
          Your results to share<br />
          <textarea :value="textToShare" />
        </p>
        <!-- </div> -->
        <div v-if="!gameWon">
          <p style="text-align: center; color: #c00">
            You lost. The word was <strong>{{ word.toUpperCase() }}</strong
            >. Try again tomorrow!
          </p>
        </div>
      </div>

      <p>
        <a href="javascipt:void(0)" @click.prevent="resetConfirm = true"
          >Reset everything</a
        >
      </p>
      <p v-if="resetConfirm">
        Are you sure?&nbsp;&nbsp;&nbsp;
        <a href="javascipt:void(0)" @click.prevent="reset">Yes</a
        >&nbsp;&nbsp;&nbsp;
        <a href="javascipt:void(0)" @click="resetConfirm = false">Cancel</a>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
table {
  width: 100%;
  table-layout: fixed;
}
.caps {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  vertical-align: top;
  text-align: center;
}
.vals {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 600;
  width: 16.66666%;
  font-size: 24px;
  text-align: center;
}
.copied {
  color: #538d4e;
  text-align: center;
}
.statistics {
  textarea {
    height: 120px;
    width: 100%;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);

    max-width: 468px;
    margin: 0 auto;
    padding: 0 16px;
    h2 {
      text-transform: uppercase;
      font-size: 16px;
      line-height: 50px;
      margin: 0;
      text-align: center;
    }
    p {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 14px;
      strong {
        font-weight: 600;
        &.green {
          color: #538d4e;
        }
        &.yellow {
          color: #b59f3b;
        }
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;

    display: block;
    border: none;
    padding: 13px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
.share-button {
  display: block;
  border: none;
  padding: 0;
  width: 200px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
  text-decoration: none;
  background: rgb(85, 186, 76);
  background: linear-gradient(
    150deg,
    rgba(85, 186, 76, 1) 0%,
    rgba(71, 138, 65, 1) 100%
  );
  border-radius: 8px;
  color: #ffffff;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 20px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: 600;
  img,
  svg {
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}
</style>
