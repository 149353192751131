<script>
export default {
  name: "Settings",
};
</script>

<template>
  <div class="settings">
    <button type="button" class="settings__close" @click="$emit('close')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path
          fill="currentColor"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        ></path>
      </svg>
    </button>
    <h2>Settings</h2>
    <p>Test 2</p>
  </div>
</template>

<style lang="scss">
.settings {
  position: relative;
  max-width: 468px;
  margin: 0 auto;
  padding: 0 16px;
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 50px;
    margin: 0;
    text-align: center;
  }
  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    strong {
      font-weight: 600;
      &.green {
        color: #538d4e;
      }
      &.yellow {
        color: #b59f3b;
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;

    display: block;
    border: none;
    padding: 13px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
</style>
