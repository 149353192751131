<script>
import { GREEN, YELLOW, GREY } from "./algorithm.js";
export default {
  name: "Square",
  props: {
    character: {
      type: String,
      default: null,
    },
    color: {
      type: Number,
      default: null,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      GREEN,
      YELLOW,
      GREY,
      spinning: false,
    };
  },
  watch: {
    character(newChar, oldChar) {
      this.spinning = true;
      if (newChar !== oldChar) {
        setTimeout(() => {
          this.spinning = false;
        }, 700 + this.position * 500);
      }
    },
  },
};
</script>

<template>
  <span class="square" :class="{ spinning }">
    <span
      class="square__inner"
      :class="{
        yellow: color === YELLOW,
        green: color === GREEN,
        grey: color === GREY && character !== null,
      }"
    >
      <span class="square__char"> {{ spinning ? "" : character }}</span>
    </span>
  </span>
</template>

<style lang="scss">
.square {
  box-sizing: border-box;
  float: left;
  display: block;
  width: 20%;
  position: relative;
  color: #ffffff;
  &:before {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: 100%;
  }
  &__inner {
    box-sizing: border-box;
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 2px solid #d3d6da;
    &.green {
      border: 0 solid transparent;
      background: rgb(220, 74, 255);
      background: linear-gradient(
        150deg,
        rgba(220, 74, 255, 1) 0%,
        rgba(182, 36, 255, 1) 100%
      );
    }
    &.yellow {
      border: 0 solid transparent;
      background: rgb(247, 225, 85);
      background: linear-gradient(
        150deg,
        rgb(245, 225, 96) 0%,
        rgb(211, 188, 85) 100%
      );
    }
    &.grey {
      border: 0 solid transparent;
      background-color: #787c7e;
    }

    .spinning & {
      &.green,
      &.yellow,
      &.grey {
        border: 2px solid #d3d6da;
      }
      background-image: url(../assets/spin.jpg);
      background-position: 0px 0px;
      background-size: 100% auto;
      animation: play 0.8s steps(8) infinite;
    }
  }
  &__char {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
  }
}
@keyframes play {
  100% {
    background-position: 0px -1200px;
  }
}
</style>
