import get from "lodash/get";
import countBy from "lodash/countBy";
export const GREEN = 2;
export const YELLOW = 1;
export const GREY = 0;
const ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("");

export const isGameOver = function (word, guessedWords) {
  return (
    guessedWords.length === 6 || guessedWords[guessedWords.length - 1] === word
  );
};

export const isGameWon = function (word, guessedWords) {
  return (
    isGameOver(word, guessedWords) &&
    guessedWords[guessedWords.length - 1] === word
  );
};

export const wordScore = function (word, guess) {
  // console.log("scoring guess ", guess);
  const splitWord = word.split("");
  const splitGuess = guess.split("");

  const wordHasThisManyOfEachOfItsChars = countBy(splitWord);
  const runningTally = splitGuess.reduce((a, v) => {
    a[v] = 0;
    return a;
  }, {});
  //const greens = guess.map((g, i) => g === word[i]);

  return splitGuess.reduce(
    (a, guessedCharacter, i, arr) => {
      a.runningTally[guessedCharacter]++;

      if (guessedCharacter === word[i]) {
        a.score.push(GREEN);
      } else if (!(guessedCharacter in wordHasThisManyOfEachOfItsChars)) {
        a.score.push(GREY);
      } else if (
        runningTally[guessedCharacter] <= // e # 1
          wordHasThisManyOfEachOfItsChars[guessedCharacter] && // 2 e's
        /* this char is not exhaustively accounted for by green tiles later */
        arr.filter((c, j) => c === guessedCharacter && c === word[j] && j > i)
          .length <=
          wordHasThisManyOfEachOfItsChars[guessedCharacter] -
            runningTally[guessedCharacter]
      ) {
        a.score.push(YELLOW);
      } else {
        a.score.push(GREY);
      }

      return a;
    },
    { runningTally, score: [] }
  ).score;
};

export const cumulativeInformation = function (word, guessedWords) {
  // console.log("cumulativeInformation ", guessedWords);
  const information = ALPHABET.reduce((a, v) => {
    a[v] = {
      min: null,
      max: null,
      posIncl: {},
      posExcl: {},
    };
    return a;
  }, {});

  guessedWords.forEach((guessedWord) => {
    const splitGuess = guessedWord.split("");
    const scoresForGuess = wordScore(word, guessedWord);

    const yellowAndGreenCountByCharacter = splitGuess.reduce((a, v, i) => {
      if (scoresForGuess[i] !== GREY) {
        if (!(v in a)) {
          a[v] = 0;
        }
        a[v]++;
      }
      return a;
    }, {});

    splitGuess.forEach((guessedChar, i) => {
      const score = scoresForGuess[i];
      const x = information[guessedChar];

      if (score === GREEN) {
        // if we havent seen this character green in this location before
        if (!(i in x.posIncl)) {
          x.posIncl[i] = true;
          x.min = Math.max(
            Object.keys(x.posIncl).length,
            yellowAndGreenCountByCharacter[guessedChar]
          );
        }
      }

      if (score === YELLOW) {
        // if we havent seen this character yellow in this location before
        if (!(i in x.posExcl)) {
          x.posExcl[i] = true;

          // if we've ever seen evidence that multiple of this character are in the word, require at least that many
          x.min = Math.max(x.min, yellowAndGreenCountByCharacter[guessedChar]);
        }
      }

      if (score === GREY) {
        if (guessedChar in yellowAndGreenCountByCharacter) {
          x.max = yellowAndGreenCountByCharacter[guessedChar];
        } else {
          x.max = 0;
        }
      }
    });
  });
  // console.log(information);
  return information;
};

export const filteredWordList = function (word, wordList, guessedWords) {
  const info = cumulativeInformation(word, guessedWords);
  // console.log("filteredWordList", wordList.length);

  const downTo = wordList.filter((word) => {
    const splitWord = word.split("");
    const counts = countBy(splitWord);

    for (let keyChar in info) {
      let charInfo = info[keyChar];
      // word has too many of a character
      if (charInfo.max !== null && charInfo.max < counts[keyChar]) {
        return false;
      }
      // word has too few of a character
      if (charInfo.min !== null && charInfo.min > get(counts, [keyChar], 0)) {
        return false;
      }
      // a position we required to have the character, did not
      if (
        Object.keys(charInfo.posIncl).some(
          (inclKey) => splitWord[inclKey] !== keyChar
        )
      ) {
        return false;
      }
      // a position we required to NOT have the character, did
      if (
        Object.keys(charInfo.posExcl).some(
          (exclKey) => splitWord[exclKey] === keyChar
        )
      ) {
        return false;
      }
    }
    return true;
  });

  // console.log("filteredWordList down to", downTo);
  return downTo;
};
