<script>
import get from "lodash/get";
import { DateTime, Interval } from "luxon";
import HowToPlay from "./components/HowToPlay.vue";
import Statistics from "./components/Statistics.vue";
import Settings from "./components/Settings.vue";
import Game from "./components/Game.vue";
import { isGameOver } from "./components/algorithm.js";
import SubtitleRotation from "./components/SubtitleRotation.vue";

export default {
  name: "App",
  components: {
    Game,
    HowToPlay,
    Statistics,
    Settings,
    SubtitleRotation,
  },
  data() {
    return {
      howToPlayOpen: false,
      statisticsOpen: false,
      settingsOpen: false,
      dayIndex: null,
      wordList: null,
      guessedWords: [],
      stats: {},
    };
  },
  watch: {
    guessedWords() {
      this.saveStateToLocalStorage();
    },
  },
  methods: {
    setDayIndex() {
      this.dayIndex =
        new Interval.fromDateTimes(
          DateTime.fromISO("2022-02-14"),
          DateTime.fromISO(DateTime.now().toISODate())
        ).count("days") - 1;
    },
    saveStateToLocalStorage() {
      const randleData = {
        currentGame: {
          [this.dayIndex]: this.guessedWords,
        },
        stats: this.stats,
      };
      localStorage.setItem("randleData", JSON.stringify(randleData));
    },
    loadStateFromLocalStorage() {
      const randleData = JSON.parse(localStorage.getItem("randleData"));
      if (randleData) {
        this.guessedWords = get(randleData, ["currentGame", this.dayIndex], []);
        this.stats = get(randleData, ["stats"], {});
      }
    },
    fetchWordList() {
      fetch(`/words.json?v=${this.dayIndex}`)
        .then((response) => response.json())
        .then((data) => {
          this.wordList = data;
        });
    },
    handleGuessedWords(guessed) {
      // console.log("handling guessed", guessed);
      this.guessedWords = guessed;
      if (isGameOver(this.word, this.guessedWords)) {
        this.gameOver();
      }
    },
    gameOver() {
      const result =
        this.guessedWords[this.guessedWords.length - 1] === this.word
          ? this.guessedWords.length
          : 0;
      this.stats = { ...this.stats, [this.dayIndex]: result };
      setTimeout(() => {
        this.statisticsOpen = true;
      }, 3200);
    },
    reset() {
      localStorage.removeItem("randleData");
      this.guessedWords = [];
      this.stats = {};
    },
  },
  computed: {
    word() {
      if (!this.wordList) {
        return null;
      }
      return this.wordList[this.dayIndex % this.wordList.length];
    },
  },
  mounted() {
    this.setDayIndex();
    this.loadStateFromLocalStorage();
    this.fetchWordList();
  },
};
</script>

<template>
  <div id="app" class="app light-mode">
    <div class="nav" v-show="!howToPlayOpen && !settingsOpen">
      <button
        class="nav-button nav-button--how-to-play"
        @click="howToPlayOpen = true"
      >
        <svg
          style="width: 24px; height: 24px"
          width="256px"
          height="256px"
          viewBox="0 0 256 256"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Artboard"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <path
              d="M128,16 C189.855892,16 240,66.144108 240,128 C240,189.855892 189.855892,240 128,240 C66.144108,240 16,189.855892 16,128 C16,66.144108 66.144108,16 128,16 Z M128,36 C77.189803,36 36,77.189803 36,128 C36,178.810197 77.189803,220 128,220 C178.810197,220 220,178.810197 220,128 C220,77.189803 178.810197,36 128,36 Z"
              id="Combined-Shape"
              fill="#000000"
            ></path>
            <g
              id="?"
              transform="translate(90.678179, 59.098000)"
              fill="#000000"
              fill-rule="nonzero"
            >
              <path
                d="M43.7458214,0 C56.9884881,0 67.6526548,3.59716667 75.7383214,10.7915 C83.8239881,17.9858333 87.8668214,27.886 87.8668214,40.492 C87.8668214,52.9706667 83.8558214,62.4888333 75.8338214,69.0465 C67.8118214,75.6041667 57.1794881,78.883 43.9368214,78.883 L43.1728214,93.59 L19.6798214,93.59 L18.7248214,61.12 L27.3198214,61.12 C38.3978214,61.12 46.9291548,59.6556667 52.9138214,56.727 C58.8984881,53.7983333 61.8908214,48.4503333 61.8908214,40.683 C61.8908214,35.0803333 60.2673214,30.6236667 57.0203214,27.313 C53.7733214,24.0023333 49.3484881,22.347 43.7458214,22.347 C37.8884881,22.347 33.3363214,23.9386667 30.0893214,27.122 C26.8423214,30.3053333 25.2188214,34.6983333 25.2188214,40.301 L0.00682142857,40.301 C-0.120511905,32.5336667 1.53482143,25.594 4.97282143,19.482 C8.41082143,13.37 13.4723214,8.595 20.1573214,5.157 C26.8423214,1.719 34.7051548,0 43.7458214,0 Z M31.3308214,139.239 C26.4921548,139.239 22.5129881,137.742833 19.3933214,134.7505 C16.2736548,131.758167 14.7138214,128.033667 14.7138214,123.577 C14.7138214,119.120333 16.2736548,115.395833 19.3933214,112.4035 C22.5129881,109.411167 26.4921548,107.915 31.3308214,107.915 C36.0421548,107.915 39.9258214,109.411167 42.9818214,112.4035 C46.0378214,115.395833 47.5658214,119.120333 47.5658214,123.577 C47.5658214,128.033667 46.0378214,131.758167 42.9818214,134.7505 C39.9258214,137.742833 36.0421548,139.239 31.3308214,139.239 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </svg>
      </button>
      <button
        class="nav-button nav-button--statistics"
        @click="statisticsOpen = true"
      >
        <svg
          style="width: 24px; height: 24px"
          width="256px"
          height="256px"
          viewBox="0 0 256 256"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Artboard-Copy-2"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <rect
              id="Rectangle"
              fill="#000000"
              x="88"
              y="28"
              width="20"
              height="200"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="148"
              y="28"
              width="20"
              height="200"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="208"
              y="162"
              width="20"
              height="66"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="28"
              y="115"
              width="20"
              height="113"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="28"
              y="115"
              width="80"
              height="20"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="88"
              y="28"
              width="80"
              height="20"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="148"
              y="162"
              width="80"
              height="20"
            ></rect>
            <rect
              id="Rectangle"
              fill="#000000"
              x="28"
              y="208"
              width="200"
              height="20"
            ></rect>
          </g>
        </svg>
      </button>
      <h1 class="main-title"><span>R</span>andle</h1>
      <SubtitleRotation />
    </div>
    <Game
      v-if="word"
      :word="word"
      :guessed-words="guessedWords"
      @guessed="handleGuessedWords"
      v-show="!howToPlayOpen && !settingsOpen"
    />
    <HowToPlay v-if="howToPlayOpen" @close="howToPlayOpen = false" />
    <Statistics
      v-if="statisticsOpen"
      :stats="stats"
      :day-index="dayIndex"
      :word="word"
      :guessed-words="guessedWords"
      @close="statisticsOpen = false"
      @reset="reset"
    />
    <Settings v-if="settingsOpen" @close="settingsOpen = false" />
  </div>
</template>

<style lang="scss">
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.app {
  height: 100%;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav {
  position: relative;
  border-bottom: 1px solid #d3d6da;
}
.nav-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;

  display: block;
  border: none;
  padding: 13px;
  width: 50px;
  height: 50px;
  text-align: center;
  cursor: pointer;

  .light-mode & {
    color: #000000;
  }

  &--how-to-play {
    left: 0;
    top: 0;
  }
  &--statistics {
    right: 0;
    top: 0;
  }
}
.main-title {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  padding-top: 4px;
  display: block;
  span {
    display: inline-block;
    transform: rotate(15deg);
    color: rgba(182, 36, 255, 1);
  }
  @media screen and (max-width: 400px) {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.sub-title {
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 4px;
  display: block;
  padding-left: 50px;
  padding-right: 50px;
  color: #333;
}
</style>
