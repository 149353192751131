<script>
import Square from "./Square.vue";
export default {
  name: "HowToPlay",
  components: {
    Square,
  },
};
</script>

<template>
  <div class="how-to-play">
    <button type="button" class="how-to-play__close" @click="$emit('close')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path
          fill="currentColor"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        ></path>
      </svg>
    </button>
    <h2>How to play</h2>
    <p>
      Try your luck to <strong>SPIN</strong> for the hidden word in six tries.
    </p>
    <p>
      Each <strong>SPIN</strong> will enter a random, but valid, five-letter
      word.
    </p>
    <p>
      After each <strong>SPIN</strong>, the color of the tiles will change to
      provide clues for how close your <strong>SPIN</strong> was to the hidden
      word.
    </p>
    <div style="width: 300px; display: inline-block">
      <Square character="h" :color="1" :position="0" />
      <Square character="e" :color="2" :position="1" />
      <Square character="l" :color="0" :position="2" />
      <Square character="l" :color="0" :position="3" />
      <Square character="o" :color="1" :position="4" />
    </div>
    <p>
      Your next <strong>SPIN</strong> will respect the clues provided. A word
      will be entered that keeps <strong class="purple">PURPLE</strong> tiles'
      characters in the same position as before, and moves
      <strong class="yellow">YELLOW</strong> tiles to new positions.
    </p>
    <p>There is no strategy.</p>
    <p>This is a slot machine.</p>
    <p>
      Just mash the <strong>SPIN</strong> button and see if you're lucky or not.
      Then share your results as though you deserve them.
    </p>
    <p>
      <strong> RANDLE will make a new hidden word available each day! </strong>
    </p>
  </div>
</template>

<style lang="scss">
.how-to-play {
  position: relative;
  max-width: 468px;
  margin: 0 auto;
  padding: 0 16px;
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 50px;
    margin: 0;
    text-align: center;
  }
  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    strong {
      font-weight: 600;
      &.purple {
        color: rgba(182, 36, 255, 1);
      }
      &.yellow {
        color: #b59f3b;
      }
    }
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;

    display: block;
    border: none;
    padding: 13px;
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}
</style>
